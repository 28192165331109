.contain {
    padding: 5vw;
    background-image: url('../../img/74912408-fe1f-439d-b71f-0da86e0cbceb.jpg'); 
    background-size: cover;
    background-position: center;
    color: #333;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}

.contain h1 {
    font-size: 2.3em;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
    line-height: 1.2;
    margin-top: 70px;

}

.contain p {
    font-size: 1.5em;
    margin-bottom: 30px;
    line-height: 1.5;
}

.button-55 {
    background-color: #fff;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #41403e;
    cursor: pointer;
    display: inline-block;
    font-family: Neucha, sans-serif;
    font-size: 1rem;
    line-height: 23px;
    outline: none;
    padding: .75rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 20px;
}

.button-55:hover {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
}

.button-55:focus {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}


@media screen and (max-width: 480px) {
    .contain {
        padding: 2vw; 
    }
    .contain h1 {
        font-size: 1.3em;
        margin-top: 100px;
    }
    .contain p {
        font-size: 1.2em;
        padding: 10px;
    }


  
    
}