@import url("https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900&display=swap");
.mainNav__logo img {
    width: 200px;
    height: auto;
    margin-right: 100px;
    margin-top: 30px; 
  
  }

.mainNav {
  width: 100%;
  height: 120px;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #393a3b;
  text-transform: uppercase;
  padding: 0 40px;
  position: fixed;
      background-color: transparent; 

}




@media screen and (max-width: 799px) {
  .mainNav {
    padding: 0 20px;
    
  }
}
.mainNav__logo {
  font-weight: 800;
  letter-spacing: 1px;
  font-size: 18px;
}
.mainNav__links {
  display: flex;

}
@media screen and (max-width: 799px) {
  .mainNav__links {
    display: none;
  }
}
.mainNav__link {
  letter-spacing: 1px;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
  box-shadow: inset 0px -10px 0px rgba(255, 255, 255, 0.5);
  transition: all 0.4s ease, transform 0.2s ease;
  padding: 2px 4px;
  transform: translateY(0px);
  
}
.mainNav__link:hover {
  transform: translateY(-5px);
  box-shadow: inset 0px -20px 0px white;
}
.mainNav__icon {
  background-image: url(https://rafaelalucas91.github.io/assets/icons/black/icon-141.svg);
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;  
  background-position: center;
  display: none;
  margin-right: 250px;
}
@media screen and (max-width: 799px) {
 
}

@media screen and (max-width: 799px) {
    .mainNav {
      background-color: transparent; 
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
      
    }
  
    .mainNav__logo {
      margin-left: 20px; 
      margin-right: 20px; 
    }
  
    .mainNav__links {
      display: none; 
      flex-direction: column; 
      align-items: flex-start;
      margin-top: 20px;
    }
  
    .mainNav__link {
      margin-left: 0; /* Reset left margin for links */
    }
  
    .mainNav__icon {
      display: block; 
      margin-right: 30px; 
      
    }
  
    .mainNav__icon svg {
      width: 24px;
      height: auto;
      cursor: pointer; 
    }
  
    .mainNav.open .mainNav__links {
      display: flex;
      margin-top: 100%;
    }
  }