@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700,800");


.link {
  display: block;
  text-align: center;
  color: #777;
  text-decoration: none;
  padding: 10px;
}

.movie_card {
  position: relative;
  display: block;
  width: 1200px;
  height: 350px;
  margin: 30px auto;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.4s;
  box-shadow: 0px 0px 120px -25px rgba(0, 0, 0, 0.5);
  background-color: white;
}

.movie_card:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 80px -25px rgba(0, 0, 0, 0.5);
  transition: all 0.4s;
}

.movie_card .info_section {
  position: relative;
  width: 100%;
  height: 100%;
  background-blend-mode: multiply;
  z-index: 2;
  border-radius: 10px;
}

.movie_card .info_section .movie_header {
  position: relative;
  padding: 25px;
  height: 40%;
  display: grid;
}

.movie_card .info_section .movie_header h1 {
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  color: black;
}

.movie_card .info_section .movie_desc p {
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  color: black;
  margin-top: 50px;

}

.movie_card .info_section .movie_header .minutes {
  display: inline-block;
  margin-top: 15px;
  color: #555;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.movie_card .info_section .movie_header .type {
  display: inline-block;
  color: #959595;
  margin-left: 10px;
}

.movie_card .info_section .movie_header .locandina {
  position: relative;
  float: left;
  margin-right: 20px;
  height: 120px;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  width: 200px;
}

.movie_card .info_section .movie_desc {
  padding: 25px;
  height: 50%;
}

.movie_card .info_section .movie_desc .text {
  color: #545454;
}

.movie_card .blur_back {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  right: 0;
  background-size: cover;
  border-radius: 11px;
}



.contentp2 h1 {
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  font-size: 3rem;
  color: #1E3A8A;
  /* Royal Blue color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInUp 1s ease-in-out;
  text-align: center;
  /* Center the text horizontally */
  justify-content: center;
  /* margin-top: 50px; */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media screen and (min-width: 768px) {
  .movie_header {
    width: 65%;
  }

  .movie_desc {
    width: 50%;
  }

  .info_section {
    background: linear-gradient(to right, #e5e6e6 50%, transparent 100%);
  }

  .blur_back {
    width: 80%;
    background-position: -100% 10% !important;
  }


}

@media screen and (max-width: 768px) {
  .movie_card {
    width: 95%;
    margin: 70px auto;
    min-height: 350px;
    height: auto;
  }

  .blur_back {
    width: 100%;
    background-position: 50% 50% !important;
  }

  .movie_header {
    width: 100%;
  }

  .movie_desc {
    width: 100%;
  }

  .info_section {
    background: linear-gradient(to top, #e5e6e6 50%, transparent 100%);
    display: inline-grid;
  }

  .movie_card .info_section .movie_header h1 {
    margin-bottom: -50px;
  }

  .contentp2 h1 {
    font-size: 18px;
    /* Adjust font size for smaller screens */
    border-radius: 50px 0px 50px 0px;
    margin-left: 20px;
    width: 90%;
    margin-bottom: -30px;
  }
}

.bright_back {
  background: url("../../../img/Islampur.jpg");

}

.tomb_back {
  background: url("../../../img/DSC02189copy.JPG");

}

.ave_back {
  background: url("../../../img/Sarvahit1.jpeg");

}

.ave_back1 {
  background: url("../../../img/SBC.jpg");

}

.ave_back2 {
  background: url("../../../img/4photo1.jpeg");

}
.seemore-cp{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: 'Times New Roman', serif
}
.seemore-cp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: 'Times New Roman', serif;
}

.button-28 {
  color: #1E3A8A;
  text-transform: uppercase;
  text-decoration: none;
  border: 2px solid #1E3A8A;
  padding: 10px 20px;
  font-size: 17px;
  font-weight: bold;
  background: transparent;
  position: relative;
  transition: all 1s;
  overflow: hidden;
  border-radius: 25px;
  margin-bottom: 20px;
}

.button-28:hover {
  color: white;
  background-color: #1E3A8A;

}


.button-28:hover::before {
  width: 160%;
}
