@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");

.containerab {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 40px auto; /* Center the container horizontally */
}

.containerab .cardab {
  position: relative;
  min-width: 230px;
  height: 260px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 10px;
  transition: 0.5s;
}

.containerab .cardab:nth-child(1) .boxab .contentab p {
  color: #2196f3;
}

.containerab .cardab:nth-child(2) .boxab .contentab p {
  color: #e91e63;
}

.containerab .cardab:nth-child(3) .boxab .contentab p {
  color: #23c186;
}

.containerab .cardab:nth-child(4) .boxab .contentab p {
  color: #f0f324;
}

.containerab .cardab .boxab {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.containerab .cardab .boxab:hover {
  transform: translateY(-50px);
}

.containerab .cardab .boxab:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.containerab .cardab .boxab .contentab {
  padding: 20px;
  text-align: center;
}


.containerab .cardab .boxab .contentab h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.containerab .cardab .boxab .contentab p {
  font-size: 2.5rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerab .cardab .boxab .contentab a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.containerab .cardab .boxab .contentab a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

@media (max-width: 768px) {
  .containerab {
    flex-direction: column;
    align-items: center; 
    margin: 10px auto; 

  }

  .containerab .cardab {
    width: calc(80% - 80px);
    height: 200px;
    margin-left: 30px;
  }
  .containerab .cardab .boxab .contentab h3 {
    font-size: 1.5rem;
    margin-top: 50px;
   
  }
  
}

@media (max-width: 768px) {
  .containerab {
    flex-direction: column; 
    align-items: stretch;
  }

  .containerab .cardab {
    width: calc(100% - 60px); 
  }
}