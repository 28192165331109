
.mainHeading {
  
  max-width: 100%;
  max-height: 100%;
  position: relative;
  padding: 0 40px;
  background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);

}
@media screen and (max-width: 799px) {
  .mainHeading {
    padding: 0 20px;
  }
}
.mainHeading__content {
  max-width: 1110px;
  min-height: 650px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 799px) {
  .mainHeading__content {
    min-height: 500px;
  }
}
.mainHeading__text {
  z-index: 1;
  color: #637498;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 40px;
  max-width: 620px;
  margin-top: 100px;
  width: 70%;
  backdrop-filter: blur(8px);
  animation: text 0.8s 0.6s ease backwards;
  position: relative;
}
.mainHeading__text:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  animation: line 0.8s 0.6s ease backwards;
}
@keyframes line {
  0% {
    right: 0;
    width: 100%;
    opacity: 0;
  }
}
@media screen and (max-width: 799px) {
  .mainHeading__text {
    padding: 20px;
    margin: 90px 0 40px 0;

  }
}
@keyframes text {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }

}
.mainHeading__preTitle {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 2px;
  margin-bottom: 2px;
  color: #637498;
  font-size: 70px;
  font-family: 'Tiro Devanagari Hindi', sans-serif;
  
}
.mainHeading__title {
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 2px;
  margin-bottom: 24px;
  font-size: 40px;
  color: #637498;
}
@media screen and (max-width: 799px) {
  .mainHeading__title {
    margin-bottom: 16px;
    font-size: 28px;
  }
}
.mainHeading__description {
  letter-spacing: 0.5px;
  font-size: 24px;
  line-height: 26px;
  font-weight: 800;
}
@media screen and (max-width: 799px) {
  .mainHeading__description {
    font-size: 14px;
  }
}
.mainHeading__image {
  right: 0;
  max-width: 600px;
  width: 60%;
  height: 600px;
  transform: translatey(100px);
  position: absolute;
  overflow: hidden;
  animation: image 0.6s 0.2s ease backwards;
}
@media screen and (max-width: 799px) {
  .mainHeading__image {
    height: 480px;
    width: 70%;
    transform: translatey(80px);
    right: -6%;
  }
}
@keyframes image {
  0% {
    opacity: 0;
    transform: translatey(200px);
  }
}
.mainHeading__image:before, .mainHeading__image:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0%;
  top: 100%;
  background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);
  opacity: 1;
  left: 0;
}
.mainHeading__image:before {
  animation: imageBefore 1s 0.2s ease backwards;
}
@keyframes imageBefore {
  0% {
    height: 100%;
    top: 0;
  }
}
.mainHeading__image:after {
  background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);
  height: 100%;
  top: 0;
  opacity: 0.2;
}
.mainHeading__image img {
  width: 100%;
  height: 100%;
}

.cta {
  padding: 16px 32px;
  color: #637498;
  background-color: transparent;
  border: 1px solid rgba(99, 116, 152, 0.4);
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  margin-top: 32px;
  cursor: pointer;
  box-shadow: inset 0px 0px 0px rgba(99, 116, 152, 0.2);
  transition: all 0.4s ease;
}
.cta:hover {
  border: 1px solid rgba(99, 116, 152, 0.1);
  box-shadow: inset 0px -80px 0px rgba(99, 116, 152, 0.1);
  transform: translateY(-5px);
}
/* Styling for the WhatsApp icon */
.whatsapp-icon {
  font-size: 1.5em;
  color: green; /* Change the color of the icon */
  

}

/* Optional: Hover effect */
.whatsapp-icon:hover {
  transform: translate(-50%, -50%) scale(1.2); /* Adjust the scale factor for zoom effect */
}

@media screen and (max-width: 799px) {
  .cta {
    margin-top: 16px;
  }
}


