  /* * {
      box-sizing: border-box;
    }
    
    body {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      
      
      
      
      eight: 100vh;
      font-family: "Montserrat", sans-serif;
    } */
    
    .wrapper-cp{
    
      display: grid;
      flex-flow: row wrap;
      justify-content: center;
    
  }
  .inner-cp {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 1.5em;
    transform: translateZ(80px) scale(0.94);
  }

  .card-cp {
    width: 420px;
    height: 540px;
    margin: 1em;
    perspective: 1500px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .card-cp .content-cp {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
  }

  .more-cp {
    display: none;
  }
  .more-cp:checked ~ .content-cp {
    transform: rotateY(180deg);
  }

  .front-cp,
  .back-cp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    border-radius: 6px;
  }
  .front-cp .inner-cp,
  .back-cp .inner-cp {
    height: 100%;
    display: grid;
    padding: 1.5em;
    transform: translateZ(80px) scale(0.94);
  }

  .front-cp {
    background-color: #fff;
    background-size: cover;
    background-position: center center;
  }
  .front-cp:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 6px;
    backface-visibility: hidden;
    background: linear-gradient(40deg, rgba(67, 137, 243, 0.281), rgba(255, 242, 166, 0.336));
  }
  .front-cp .inner-cp {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 1.5em;
    transform: translateZ(80px) scale(0.94);
  }
  .front-cp h2 {
    grid-row: 2;
    margin-bottom: 0.3em;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #ffffff;
    font-weight: 500;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    font-size: 24px;
  }
  .front-cp .rating {
    grid-row: 3;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    display: flex;
    flex-flow: row nowrap;
  }
  .front-cp .rating i {
    margin: 0 1px;
  }

  .back-cp {
    transform: rotateY(180deg);
    background-color: #fff;
    border: 2px solid #f0f0f0;
  }
  .back-cp .inner-cp {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.8em;
    justify-items: center;
  }
  .back-cp .info-cp {
    position: relative;
    display: flex;
    align-items: center;
    color: #ED3942;
    grid-row: 3;
    
  }
  .inner-cp h2{
    font-size: 44px;
    color: white;
    text-shadow: 4px 4px 4px #355cc9;; /* Text shadow properties */
    position: relative; /* Ensure proper z-index */
    z-index: 1; /* Set z-index */

  }
  .back-cp .info-cp::before {
    content: "";
    position: relative;
    left: -0.9em;
    height: 18px;
    width: 1px;
    background-color: #e92121;
  }

  
  .back-cp .info-cp span {
    font-size: 2em;
    font-weight: 700;
  }
  .back-cp .info-cp i {
    font-size: 1.2em;
  }
  .back-cp .info-cp i:before {
    background: linear-gradient(40deg, #355cc9, #438af3);
    -webkit-text-fill-color: transparent;
    /* -webkit-background-clip: text; */
  }
  .back-cp .info-cp .icon-cp {
    margin-left: 0.3em;
    /* padding-left: 12px; */
  }
  .back-cp .info-cp .icon-cp span {
    display: block;
    margin-top: -0.25em;
    font-size: 0.8em;
    font-weight: 800;
    /* white-space: nowrap; */
  }
  .back-cp .description-cp {
    grid-row: 5;
    grid-column: 1/-1;
    font-size: 1.0em;
    border-radius: 5px;
    font-weight: 600;
    line-height: 1.4em;
    overflow: auto;
    color: #1E3A8A;
    margin-left: 30px;
    width: auto;
    /* padding-right: 10px; */
  }
  .back-cp .location-cp{
    font-weight: 600;
    color: #355cc9;
    grid-row: 1;
    font-size: 2.50em;
  }
  .back-cp .location-cp {
    grid-column: 1/4;
    justify-self: right;
  }


  /* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap"); */

  .wrapper-cp .onproject-title h1 {
    background-image: linear-gradient(45deg, #ff00cc, #3333ff, #00ffcc);
    background-size: 200% auto;
    color: white;
    text-align: center;
    padding: 10px;
    animation: gradientAnimation 5s ease infinite;
    font-size: 24px;
    margin-top: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 50px 0px 50px 0px;

  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  .button-cp {
    margin-top: 400px;
    grid-row: -1;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    padding: 0 1.5em;
    height: 3em;
    line-height: 2.9em;
    min-width: 3em;
    background-color: #ffffffb4;
    border: solid 2px #fff;
    color: #355cc9;
    border-radius: 4px;
    text-align: center;
    left: 50%;
    backface-visibility: hidden;
    transition: 0.3s ease-in-out;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .button-cp:hover {
    background-color: #a4edf075;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    text-shadow: none;
    color: white;
  }
  /* CSS */
  .button-18 {
  align-items: center;
  background-color: #0A66C2;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 680px;
  min-height: 50px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin-top: 50px;
  }

  .button-18:hover,
  .button-18:focus { 
  background-color: white;
  color: #0A66C2;
  }

  .button-18:active {
  background: #09223b;
  color: rgb(255, 255, 255, .7);
  }

  .button-18:disabled { 
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
  }

    
    ::-webkit-scrollbar {
      width: 5px;
    }
    
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    
    ::-webkit-scrollbar-thumb {
      background: #859ddf;
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: #355cc9;
    }


  /* Add media query for smaller screens */
  @media only screen and (max-width: 768px) {
  .wrapper-cp {
    grid-template-columns: 1fr; /* Stack cards vertically on smaller screens */
    gap: 20px; /* Add some space between stacked cards */
  }

  .card-cp {
    width: 90%; /* Allow cards to take full width on smaller screens */
    max-width: none; /* Remove max-width on smaller screens */
  }

  .front-cp .inner-cp h2 {
    font-size: 1.5em; /* Adjust font size for smaller screens */
  }

  .back-cp .info-cp {
    font-size: 0.8em; /* Adjust font size for smaller screens */
  }
  }
