.contentp1 h1 {
  font-family: 'Times New Roman', Times, serif;
}

.contentp1 h1 {
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  font-size: 3rem;
  color: #1E3A8A;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInUp 1s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.containerp {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.navmargin{
  padding-top: 130px;
}

.contentp1 {
  margin-bottom: 20px;
  position: relative;
}
/* .containerp {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-content: center;
  align-items: center;
} */
/* .button {
  width: 250px; 
  height: 40px; 
  margin: 0 auto; 
  display: flex;
  align-items: center;
 
  background-color: white;
  overflow: hidden;
  position: relative;

}

.button, .button__icon, .button__text {
  transition: all 0.3s;
}

.button .button__text {
  color: #17795E;
  font-weight: 800;
  font-size: 20px;
}

.button .button__icon {
  position: absolute;
  right: 0;
  height: 100%;
  width: 39px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button .svg {
  width: 20px;
  fill: #17795E;
  font-weight: 800;
}

.button:hover {
  background: white;
}

.button:hover .button__text {
  color: #17795E;
}

.button:hover .button__icon {
  width: 248px;
  transform: translateX(0);
}

.button:active .button__icon {
  background-color: white;
}

.button:active {
  border: 1px solid white;
} */

.button-pdf {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  background: #1E3A8A;
  border: 0px solid #1E3A8A;
  border-radius: 35px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  transition: all .25s ease;
  width: 270px;
  position: relative;
  height: 60px;
  overflow: hidden;
}
.btn:not(.loading):hover {
  box-shadow: 0px 10px 25px 0px rgba(73, 215, 92,.4);
}
.btn:not(.loading):hover i {
  transform: translate(7px);
}
.btn i {
  font-size: 1.45rem;
  position: absolute;
  left: 0px;
  pointer-events: none;
  z-index: 10;
  background: inherit;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all .25s ease;
}
.btn .text {
    white-space: nowrap; /* Prevent text from wrapping */
    position: absolute;
    left: 70px; /* Adjust positioning */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all .25s ease;
  
}
.loading-animate {
  position: absolute;
  width: 60px;
  height: 60px;
  z-index: 100;
  border-radius: 50%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: all .25s ease;
}
.loading-animate:after {
  content: '';
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-left: 3px solid #fff;
  animation: loading infinite .8s ease .05s;
  position: absolute;
}
.loading-animate:before {
  content: '';
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-left: 3px solid #fff;
  animation: loading infinite .8s linear;
  position: absolute;
  opacity: .6;
}
.btn.loading {
  width: 60px;
}
.btn.loading i {
  transform: rotate(-90deg);
  padding-bottom: 4px;
  padding-left: 3px;
}
.btn.loading .text {
  transform: translate(-140px);
}
.btn.loading .loading-animate {
  opacity: 1;
}
@keyframes loading {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .containerp {
    flex-direction: column; /* Stack items vertically */
    align-items: stretch; /* Stretch items to full width */
  }

  .contentp1 h1 {
    font-size: 18px; /* Adjust font size for smaller screens */
    border-radius: 50px 0px 50px 0px;
    margin: 10px;
    text-align: center;
  }
}